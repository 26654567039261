<template>
  <div>
    <section
      class="g-bg-pos-center g-bg-size-cover"
      style="background-image: url(assets/img/fondo-ingreso-wide.jpg);"
    >
      <div class="row no-gutters">
        <div class="col-md-6 order-md-1 g-bg-gris ">
          <div class="g-px-50 g-py-50">
            <div class=" g-mb-100">
              <h1
                class="g-color-white g-font-weight-600 g-font-size-40 g-letter-spacing-0_5 mb-4"
              >
                Defensores del Cliente
              </h1>
              <p class="lead g-color-white  font-color-subtitle2">
                La Defensoría del Cliente cuenta con un equipo con vasta
                experiencia en resolución de conflictos en materia financiera.
                Los Defensores actúan bajo las normativas dictadas por la CMF y
                prácticas bancarias, además de resguardar la privacidad de la
                información y responder en los plazos asignados las solicitudes
                realizadas por los clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container g-px-0 g-pa-60">
      <div class="row">
        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5 text-center">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Catalina_Amenabar.jpg"
                      alt="Catalina Amenábar"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Catalina Amenábar
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogada de la Universidad de Chile.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5 text-center">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Francisca_Barrientos.jpg"
                      alt="Francisca Barrientos"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Francisca Barrientos
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogada de la Universidad de los Andes.<br />MIEMBRO DEL
                  COLEGIO DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5 text-center">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Jose_Ignacio_Diaz_Villalobos.jpg"
                      alt="Jose Ignacio Diaz Villalobos"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  José Ignacio Díaz Villalobos
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad Católica.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5 text-center">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/bernardo-edwards.png"
                      alt="bernardo-edwards"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Bernardo Edwards Edwards
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad de Chile.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Igor_Stancic-Rokotov.jpg"
                      alt="igor-stantic-rokotov"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Igor Stantic-Rokotov B.
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad de Chile.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Jose_Agustin_Errazuriz.jpg"
                      alt="jose-errazuriz"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  José Agustín Errázuriz Barros
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad Católica.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/luis-arostegui.png"
                      alt="luis-arostegui"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Luis Aróstegui Puerta de Vera
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad Católica.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/pedro-ibarra.png"
                      alt="pedro-ibarra"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Pedro Ibarra Léniz
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad Católica.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>

        <div class="col-lg-6 g-mb-60">
          <!-- Defensor -->
          <div class="card g-brd-none g-bg-transparent rounded-0">
            <div class="row">
              <div class="col-md-5">
                <div class="text-center">
                  <div
                    class="u-shadow-v19 g-width-120 g-height-120 g-brd-around g-brd-5 g-brd-white rounded-circle mx-auto mb-4"
                  >
                    <img
                      class="img-fluid rounded-circle"
                      src="assets/img/defensores/Ricardo Costabal.jpg"
                      alt="ricardo-costabal"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-7 g-pt-10">
                <h3 class="h5 g-font-weight-600 font-color-name2">
                  Ricardo Costabal LLona
                </h3>
                <p class="g-line-height-2 font-color-subtitlename">
                  Abogado de la Universidad Católica.<br />MIEMBRO DEL COLEGIO
                  DE ABOGADOS.
                </p>
              </div>
            </div>
          </div>
          <!-- / Defensor -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  beforeMount() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.font-color-name2 {
  color: #555 !important;
  font-size: 17.5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.font-color-subtitlename {
  font-weight: 400;
  font-size: 0.9rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
}
.font-color-subtitle2 {
  font-weight: 400;
  font-size: 17px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
</style>
